:root {
  --grid: 8px;
  --primary: #f9d423;
}

.App {
  text-align: center;
  width: 80vw;
  max-width: 800px;
  margin: calc(var(--grid) * 2) auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
