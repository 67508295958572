@import url(https://fonts.googleapis.com/css?family=Open+Sans|Signika&display=swap);
html body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e1f5c4;
}

html h1 {
  font-family: "Signika", serif;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --grid: 8px;
  --primary: #f9d423;
}

.App {
  text-align: center;
  width: 80vw;
  max-width: 800px;
  margin: calc(8px * 2) auto;
  margin: calc(var(--grid) * 2) auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Cta {
  margin: calc(var(--grid)) 0;
}

.Body {
  margin: calc(var(--grid) * 4) 0;
}

.ReadMessage_Message {
  min-height: 150px;
  box-shadow: 3px 3px 6px 1px #44444436;
  background: white;
  border-radius: 5px;
  padding: 16px;
  text-align: left;
  transition: transform 500ms;
}

.Home_MessageStatsWrapper {
  min-height: 5rem;
}

.ShareMessage_TextArea {
  margin: var(--grid) 0 calc(var(--grid) * 4) 0;
}

